export const language = {
    es: {

        menuInicio: `INICIO`,
        menuInicioLink: `inicio`,
        menuTarifHor: `TARIFAS Y HORARIOS`,
        menuTarifHorLink: `tarifas-y-horarios`,
        menuActividades: `ACTIVIDADES`,
        menuActEventos: `EVENTOS`,
        menuActEventosLink: `eventos`,
        menuActVisitas: `VISITAS PEDAGOGICAS`,
        menuActVisitasLink: `visitas-pedagogicas`,
        menuGaleria: `GALERIA`,
        menuGaleriaLink: `galeria`,
        menuComprar: `COMPRAR ENTRADAS`,

        footerContacto: `CONTÁCTANOS`,
        footerConFormulario: `FORMULARIO CONTACTO`,
        footerConFormLink: `contacto`,
        footerConCorreo: `SKYCOSTANERA2@CENCOSUD.CL`,
        footerConTelefono: `+562 2916 9269 ANEXO 511269`,
        footerConDireccion: `NIVEL PB MALL CENCO COSTANERA`,
        footerEnlace: `ENLACES DE INTERÉS`,
        footerEnlaceMall: `MALL CENCO COSTANERA`,
        footerEnlaceTerm: `TÉRMINOS Y CONDICIONES`,
        footerEnlaceTermLink: `terminos-y-condiciones`,
        footerEnlaceFaq: `PREGUNTAS FRECUENTES - FAQ`,
        footerEnlaceFaqLink: `preguntas-frecuentes`,
        footerRss: `SÍGUENOS EN RRSS`,
        footerRssTrip: `Lee 15.097 opiniones sobre Sky Costanera`,

        inititparr: `¡Prepárate a vivir una experiencia única en Latinoamérica!
                    En el siguiente formulario podrás programar la fecha y el horario que más te acomode para
                    realizar tu visita y acceder a la reserva de tus tickets de forma On-Line.
                    También puedes adquirir los tickets de acceso al mirador, directamente en nuestras boleterías que se
                    ubican en Planta Baja de Cenco Costanera.`,

        inittitle: `COMIENZA TU VISITA AL MIRADOR SKY COSTANERA`,
        
        homeTarifa: `TARIFA NORMAL`,
        homeLaV: `Lunes a Jueves`,
        homeAdulto: `Adulto`,
        homeNino: `Niño`,
        homeSunset: `Sunset`,
        homeInfante: `Infantes de 0-3 años gratis`,
        homeSabDom: `Vie, Sab, Dom y Festivos`,
        homeComprar: `COMPRAR ENTRADAS`,
        homeVisita: `VISITAS GRUPALES`,
        homeVisitaPar: `Para Visitas grupales debes comunicarte con nosotros a través de nuestro formulario de contacto, 
                        te responderemos a la brevedad`,
        homeVisitaCont: `CONTACTO`,

        selectTitle: `Seleccione fecha y horario de tu visita`,
        selectTipo: `Selecciona tipo de entrada`,
        selectFecha: `Selecciona fecha`,
        selectHora: `Selecciona horario`,
        selectMayores: `Mayores de 13 años`,
        selectEntre: `Entre 4 y 12 años`,
        selectSunset: `Debe presentar su carnet de identidad`,
        selectTips: `Para niños entre 0 a 3 años no debe considerarse la compra de entradas, el ingreso de los infantes es totalmente gratuito`,
        selectContinuar: `CONTINUAR`,
        selectMsgError: `Debe seleccionar al menos un adulto como acompañante del niño`,
        selectStatusDayNormal: ``,
        selectStatusDaySpecial: `¡Atención a los horarios! verifica la disponibilidad para planificar tu visita`,
        selectStatusDayClose: `El día seleccionado Sky Costanera estará fuera de servicio`,
        selectMsgErrorAforo: `Cupo Máximo alcanzado para el día seleccionado. Intente con otra fecha`,

        resumeCompra: `Resumen de compra`,
        resumeConfirmar: `CONFIRMAR`,

        formTitle: `Información Personal`,
        formName: `Nombre*`,
        formLastName: `Apellido*`,
        formEmail: `Correo eléctronico*`,
        formRut: `Rut o Pasaporte*`,
        formBirthday: `Fecha de nacimiento`,
        formResident: `Residencia`,
        formResidentYes: `Soy residente`,
        formExtranjero: `Soy extranjero`,
        formNacion: `Nacionalidad`,
        formSearch: `Buscar...`,
        formPromo: `Mantenme informado sobre promociones y novedades`,
        formAcept: `He leído y Acepto los`,
        formTerms: `Términos y Condiciones`,
        formPolitic: ` y Políticas de Privacidad`,
        formLinkTerms: `terminos-y-condiciones`,
        formLinkPolit: `politicas-de-privacidad`,
        formService: `del servicio`,
        formBack: `IR ATRAS`,

        payCodigo: `Código de descuento`,
        payAplicar: `APLICAR DESCUENTO`,
        payDescribe: `Este cupón de descuento no es válido`,
        payMedio: `Selecciona medio de pago`,
        payMethod1: `Tarjetas Debito ó Credito`,
        payPagar: `PAGAR`,
        payReturn: `Para devoluciones de uno o más tickets comprados puedes contactarte al correo skycostanera2@cencosud.cl o directamente en nuestra boletería`,

        checkoutTitle: `HEMOS RECIBIDO TU PAGO EXITOSAMENTE`,
        checkoutDesc: `Te enviaremos la información con tus tickets al correo electrónico que nos compartiste al momento de la compra.`,
        checkoutTrx: `Transacción: `,
        checkoutMedio: `Medio de pago`,
        checkoutBoleta: `Detalle de Boleta`,
        checkoutNumBol: `Boleta N°`,
        checkoutDescarga: `DESCARGA`,
        checkoutEmail: `Su boleta y reserva será enviada al correo: `,
        checkoutInicio: `IR AL INICIO`,
        //checkoutRemember: `*Recuerda retirar tus tickets en boletería`,
        checkoutRemember: `*Recuerde llevar su código QR para acceder por torniquetes `,

        lostPageTitle: "¡Lo sentimos!",
        lostPageSubTitle: "No hemos podido encontrar la página que buscas",
        lostPageText: "Si has terminado un proceso de compra, revisa tu correo electrónico o comunícate con nosotros.",
        maxAforo: "Nos hemos quedados sin tickets!, pero no te preocupes, aun podrias conseguir los tuyos en boleteria."
    },

    'pt-br': {
        menuInicio: `COMEÇAR`,
        menuInicioLink: `comecar`,
        menuTarifHor: `TARIFAS E HORÁRIOS`,
        menuTarifHorLink: `tarifas-e-horarios`,
        menuActividades: `ATIVIDADES`,
        menuActEventos: `EVENTOS`,
        menuActEventosLink: `eventos`,
        menuActVisitas: `VISITAS EDUCACIONAIS`,
        menuActVisitasLink: `visitas-educativas`,
        menuGaleria: `GALERÍA`,
        menuGaleriaLink: `galeria`,
        menuComprar: `COMPRAR INGRESSOS`,

        footerContacto: `CONTATE-NOS`,
        footerConFormulario: `FORMULÁRIO DE CONTATO`,
        footerConFormLink: `contato`,
        footerConCorreo: `CAJAS.SKYCOSTANERA@CENCOSUD.CL`,
        footerConTelefono: `+562 2916 9269 ANEXO 511269`,
        footerConDireccion: `NÍVEL RC DO SHOPPING CENCO COSTANERA`,
        footerEnlace: `LINKS DE INTERESSE`,
        footerEnlaceMall: `SHOPPING CENCO COSTANERA`,
        footerEnlaceTerm: `TERMOS E CONDIÇÕES`,
        footerEnlaceTermLink: `termos-e-condicoes`,
        footerEnlaceFaq: `PERGUNTAS MAIS FREQUENTES - FAQ`,
        footerEnlaceFaqLink: `perguntas-frequentes`,
        footerRss: `SIGA-NOS NO RS`,
        footerRssTrip: `Leia 15.097 avaliações do Sky Costanera`,

        inititparr: `Se prepare para viver uma experiência única na América Latina! No formulário que se segue
                    poderá agendar a data e hora que mais lhe convém para fazer a sua visita e aceder à reserva
                    dos seus bilhetes online. Você também pode adquirir ingressos de acesso ao mirante,
                    diretamente em nossas bilheterias localizadas no térreo do Shopping Cenco Costanera.`,

        inittitle: `COMECE SUA VISITA AO MIRANTE SKY COSTANERA`,

        homeTarifa: `TAXA PADRÃO`,
        homeLaV: `Segunda a Quinta`,
        homeAdulto: `Adulto`,
        homeNino: `Criança`,
        homeSunset: `Sunset`,
        homeInfante: `Bebês de 0-3 anos grátis`,
        homeSabDom: `Sexta, Sáb, Dom e Feriados`,
        homeComprar: `COMPRAR INGRESSOS`,
        homeVisita: `VISITAS EM GRUPO`,
        homeVisitaPar: `Para visitas de grupo deverá contactar-nos através do nosso formulário de contacto,
                        Nós responderemos o mais breve possível`,
        homeVisitaCont: `CONTATO`,

        selectTitle: `Selecione a data e hora da sua visita`,
        selectTipo: `Selecione o tipo de ingresso`,
        selectFecha: `Selecione a data`,
        selectHora: `Selecione a hora`,
        selectMayores: `Maiores de 13 anos`,
        selectEntre: `Entre 4 e 12 anos`,
        selectSunset: `Você deve apresentar sua carteira de identidade`,
        selectTips: `Para crianças entre os 0 e os 3 anos, a compra de bilhetes não deve ser considerada, a entrada de bebés é totalmente gratuita`,
        selectContinuar: `CONTINUAR`,
        selectMsgError: `Você deve selecionar pelo menos um adulto para acompanhar a criança`,
        selectStatusDayNormal: ``,
        selectStatusDaySpecial: `Atenção aos horários! Verifique a disponibilidade para planejar sua visita`,
        selectStatusDayClose: `O dia selecionado, o Sky Costanera estará fora de serviço`,
        selectMsgErrorAforo: `Cota máxima atingida para o dia selecionado. Tente outra data`,

        resumeCompra: `Resumo da compra`,
        resumeConfirmar: `CONFIRME`,

        formTitle: `Informação Pessoal`,
        formName: `Nome*`,
        formLastName: `Sobrenome*`,
        formEmail: `E-mail*`,
        formRut: `Rut ou Passaporte*`,
        formBirthday: `Data de nascimento`,
        formResident: `Residência`,
        formResidentYes: `Eu sou residente do Chile`,
        formExtranjero: `Eu sou estrangeiro`,
        formNacion: `Nacionalidade`,
        formSearch: `Buscar...`,
        formPromo: `Mantenha-me informado sobre promoções e novidades`,
        formAcept: `Eu li e aceito o`,
        formTerms: `Termos e Condições`,
        formPolitic: `e Política de Privacidade`,
        formLinkTerms: `termos-e-condicoes`,
        formLinkPolit: `politicas-de-privacidade`,
        formService: `do serviço`,
        formBack: `VOLTAR`,

        payCodigo: `Código de desconto`,
        payAplicar: `APLICAR DESCONTO`,
        payDescribe: `Este cupom de desconto não é válido`,
        payMedio: `Selecione o meio de pagamento`,
        payMethod1: `Cartões de Débito ou Crédito`,
        payPagar: `PAGAR`,
        payReturn: `Para devolução de um ou mais ingressos adquiridos, você pode entrar em contato com o e-mail skycostanera2@cencosud.cl ou diretamente em nossa bilheteria`,

        checkoutTitle: `NÓS RECEBEMOS SEU PAGAMENTO COM SUCESSO`,
        checkoutDesc: `Nós enviaremos a informação com seus ingressos para o seu e-mail que você compartilhou conosco no momento da compra.`,
        checkoutTrx: `Transação: `,
        checkoutMedio: `Meio de pagamento`,
        checkoutBoleta: `Detalhes da fatura`,
        checkoutNumBol: `Fatura N°`,
        checkoutDescarga: `DOWNLOAD`,
        checkoutEmail: `Sua fatura e reserva será enviada para o seu e-mail: `,
        checkoutInicio: `IR PARA O INÍCIO`,
        //checkoutRemember: `*Lembre-se de retirar seus ingressos na bilheteria`,
        checkoutRemember: `*Lembre-se de trazer seu código QR para acessar pelas catracas`,
        
        lostPageTitle: "Sentimos muito!",
        lostPageSubTitle: "Não foi possível encontrar a página que você procura",
        lostPageText: "Se você concluiu um processo de compra, verifique seu e-mail ou entre em contato conosco.",
        maxAforo: "Acabamos ficando sem ingressos! Mas não se preocupe, você ainda pode conseguir os seus na bilheteria."
    },

    en: {
        menuInicio: `HOME`,
        menuInicioLink: `home`,
        menuTarifHor: `RATES AND SCHEDULES`,
        menuTarifHorLink: `rates-and-schedules`,
        menuActividades: `ACTIVITIES`,
        menuActEventos: `EVENTS`,
        menuActEventosLink: `events`,
        menuActVisitas: `EDUCATIONAL VISITS`,
        menuActVisitasLink: `educational-visits`,
        menuGaleria: `GALLERY`,
        menuGaleriaLink: `gallery`,
        menuComprar: `BUY TICKETS`,

        footerContacto: `CONTACT US`,
        footerConFormulario: `CONTACT FORM`,
        footerConFormLink: `contact`,
        footerConCorreo: `SKYCOSTANERA2@CENCOSUD.CL`,
        footerConTelefono: `+562 2916 9269 ANEXO 511269`,
        footerConDireccion: `GF LEVEL - CENCO COSTANERA MALL`,
        footerEnlace: `LINKS OF INTEREST`,
        footerEnlaceMall: `MALL CENCO COSTANERA`,
        footerEnlaceTerm: `TERMS AND CONDITIONS`,
        footerEnlaceTermLink: `terms-and-conditions`,
        footerEnlaceFaq: `FREQUENTLY ASKED QUESTIONS - FAQ`,
        footerEnlaceFaqLink: `frequently-asked-questions`,
        footerRss: `FOLLOW US ON RRSS`,
        footerRssTrip: `Read 15,097 Reviews of Sky Costanera`,

        inititparr: `Get ready to live a unique experience in Latin America! In the form that follows you
                    can schedule the date and time that suits you best to make your visit and access the
                    reservation of your online tickets. You can also purchase access tickets to the lookout,
                    directly at our ticket offices located on the ground floor of the Cenco Costanera Shopping.`,

        inittitle: `START YOUR VISIT TO THE SKY COSTANERA VIEWPOINT`,
        
        homeTarifa: `STANDARD RATE`,
        homeLaV: `Monday to Thursday`,
        homeAdulto: `Adult`,
        homeNino: `Child`,
        homeSunset: `Sunset`,
        homeInfante: `Infants 0-3 years free`,
        homeSabDom: `Fri, Sat, Sun. and Holidays`,
        homeComprar: `BUY TICKETS`,
        homeVisita: `GROUP VISITS`,
        homeVisitaPar: `For group visits you must contact us through our contact form,
                        we will reply to you as soon as possible`,
        homeVisitaCont: `CONTACT`,

        selectTitle: `Select date and time of your visit`,
        selectTipo: `Select ticket type`,
        selectFecha: `Select date`,
        selectHora: `Select time`,
        selectMayores: `Over 13 years`,
        selectEntre: `Between 4 and 12 years`,
        selectSunset: `You must present your identity card`,
        selectTips: `For children between 0 and 3 years old, the purchase of tickets should not be considered, the entrance of infants is completely free`,
        selectContinuar: `CONTINUE`,
        selectMsgError: `You must select at least one adult to accompany the child`,
        selectStatusDayNormal: ``,
        selectStatusDaySpecial: `Attention to the schedules! Check availability to plan your visit`,
        selectStatusDayClose: `The selected day, Sky Costanera will be out of service`,
        selectMsgErrorAforo: `Maximum quota reached for the selected day. Try another date`,

        resumeCompra: `Purchase summary`,
        resumeConfirmar: `CONFIRM`,

        formTitle: `Personal Information`,
        formName: `Name*`,
        formLastName: `Last Name*`,
        formEmail: `Email*`,
        formRut: `Rut or Passport*`,
        formBirthday: `Date of birth`,
        formResident: `Residence`,
        formResidentYes: `I am a resident of Chile`,
        formExtranjero: `I am a foreigner`,
        formNacion: `Nationality`,
        formSearch: `Search...`,
        formPromo: `Keep me informed about promotions and news`,
        formAcept: `I have read and accept the`,
        formTerms: `Terms and Conditions`,
        formPolitic: `and Privacy Policy`,
        formLinkTerms: `terms-and-conditions`,
        formLinkPolit: `privacy-policies`,
        formService: `of the service`,
        formBack: `BACK`,

        payCodigo: `Discount code`,
        payAplicar: `APPLY DISCOUNT`,
        payDescribe: `This discount coupon is not valid`,
        payMedio: `Select payment method`,
        payMethod1: `Debit or Credit Cards`,
        payPagar: `PAY`,
        payReturn: `For returns of one or more purchased tickets you can contact the email skycostanera2@cencosud.cl or directly at our ticket office`,

        checkoutTitle: `WE HAVE SUCCESSFULLY RECEIVED YOUR PAYMENT`,
        checkoutDesc: `We will send you the information with your tickets to your email that you shared with us at the time of purchase.`,
        checkoutTrx: `Transaction: `,
        checkoutMedio: `Payment method`,
        checkoutBoleta: `Invoice details`,
        checkoutNumBol: `Invoice N°`,
        checkoutDescarga: `DOWNLOAD`,
        checkoutEmail: `Your invoice and reservation will be sent to your email: `,
        checkoutInicio: `GO TO THE BEGINNING`,
        //checkoutRemember: `*Remember to pick up your tickets at the box office`,
        checkoutRemember: `*Remember to bring your QR code to access through turnstiles`,
 
        lostPageTitle: "We're sorry!",
        lostPageSubTitle: "We could not find the page you are looking for",
        lostPageText: "If you have completed a purchase process, check your email or contact us.",
        maxAforo: "We have run out of tickets! But don't worry, you could still get yours at the ticket office."
    },
}
